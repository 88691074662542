import {
  createContext,
  DependencyList,
  EffectCallback,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useFeedback } from './Feedback';
import { useApi } from './Api';
import { ContactTypes } from './contacts/NewContact.Context';
import { Email } from './subscriptions/Subscription.Context';
import { CompleteContact } from '../components/contact/IndividualBusinessCard';
import { isCompany } from './contacts/Contact.Context';

export interface Address {
  street: string;
  city: string;
  zip: string;
  state: string;
}

export interface ContactPerson {
  caption: string;
  person: CompleteContact;
}

export interface BasicContactInfo {
  email?: string;
  phoneNumber?: string;
  address?: Address;
  iban?: string;
  fullName?: string;
  otherEmails?: string[];
  otherAddresses?: Address[];
  otherPhoneNumbers?: string[];
  otherIbans?: string[];
  contactPersons?: ContactPerson[];
  swift?: string;
  bank?: string;
}

export interface Company extends BasicContactInfo {
  companyName: string;
  ico: string;
  dic: string;
  icDph: string;
  dphPayer?: boolean;
  contactPersonFirstName: string;
  contactPersonLastName: string;
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export interface Individual extends BasicContactInfo {
  firstName: string;
  lastName: string;
  gender?: Gender;
}

export enum ClaimStateEnum {
  pending = 'Čakajúca',
  paid = 'Zaplatená',
  cancelled = 'Zrušená',
}

export interface ClaimState {
  date: Date;
  title: string;
  note: string;
  addedBy: string;
}
export enum PaymentForm {
  Account = 'Platba na účet',
  Cash = 'Platba v hotovosti',
}

export interface Claim {
  accountingYear: string;
  issued: Date;
  maturityDate: Date;
  deliveryDate: Date;
  orderNumber?: number;
  paymentForm: PaymentForm;
  payer: Company | Individual;
  claimant: Company | Individual;
  items: ClaimItem[];
  finalPrice: number;
  discount: number | undefined;
  rawTotal: number;
  whichPayer?: ContactTypes;
  payerRef?: string;
  whichClaimant?: ContactTypes;
  claimantRef?: string;
  claimGroups?: string[];
}

export interface Invoice {
  _id: string;
  filename: string;
}
export interface ClaimGroup {
  _id: string;
  title: string;
}

export interface CreditNote {
  _id: string;
  filename: string;
}

export type CompleteClaim = Claim & {
  emailInvoiceSent: any;
  _id: string;
  accountingYear: CompleteAccountingYear;
  owner: string;
  state: ClaimState;
  stateHistory: ClaimState[];
  number: number;
  invoice: Invoice;
  creditNoteIssued?: Date;
  creditNote?: CreditNote | undefined;
  emails?: Email[];
  // claimGroups?: ClaimGroup[];
};

export interface AccountingYear {
  start: Date;
  end: Date;
  prefix: string;
  vsLength: number;
}

export interface CompleteAccountingYear extends AccountingYear {
  _id: string;
  numberOfInvoices: number;
}

export interface ClaimItem {
  rawUnitPrice: number;
  finalPrice: number;
  unitsCount: number;
  discount: number;
  title: string;
}

export enum AccountingYearValidityReportEnum {
  ok = 'Validný',
  notok = 'Nevalidný',
}

export interface AccountingYearValidityReport {
  title: AccountingYearValidityReportEnum;
  start: number;
  end: number;
  missing: number[];
  duplicates: number[];
  myEnd: number;
}

export interface IReplace {
  VS: string;
  to: string;
  number: string;
  sum: string;
}

export interface IEmailPreviewData {
  email: Email;
  data: IReplace[];
}

export type StateFilterType = 'paid' | 'cancelled' | 'pending' | 'all';

interface IClaimsContext {
  currentClaims: CompleteClaim[];
  accountingYears: CompleteAccountingYear[];
  currentAccountingYear: CompleteAccountingYear | undefined;
  currentAccountingYearReport: AccountingYearValidityReport;
  setCurrentAccountingYearId(y: string): void;
  claimGroups: ClaimGroup[];
  currentClaimGroup: ClaimGroup | undefined;
  setCurrentClaimGroupId(y: string): void;
  currentClaimGroupId: string;
  isLoading: boolean;
  submitNewAccountingYear(accountingYear: AccountingYear): Promise<boolean>;
  pullCurrentClaims(): void;
  editAccountingYearCurrent(id: string, lastNumber: number): void;
  checkAccountingYearValidity(id: string): Promise<AccountingYearValidityReport>;
  fixCreditNotes(id: string): Promise<void>;
  previewInvoiceEmails: (ids: string[]) => Promise<IEmailPreviewData>;
  sendInvoiceEmails: (emails: IEmailPreviewData) => Promise<void>;
  currentStateFilter: StateFilterType;
  setCurrentStateFilter: (state: StateFilterType) => void;
  downloadInvoices: (
    ids: { id: string; creditNoteDownloadOption: 'include' | 'exclude' | 'only' }[],
    format: string,
    filename: string
  ) => void;
  searchValue: string;
  setSearchValue: (a: string) => void;
}

const ClaimsContext = createContext<IClaimsContext>({
  currentClaims: [],
  accountingYears: [],
  currentAccountingYear: undefined,
  currentAccountingYearReport: undefined,
  setCurrentAccountingYearId: () => undefined,
  claimGroups: [],
  currentClaimGroup: undefined,
  setCurrentClaimGroupId: () => undefined,
  currentClaimGroupId: '',
  isLoading: false,
  submitNewAccountingYear: async () => false,
  pullCurrentClaims: () => undefined,
  editAccountingYearCurrent: () => undefined,
  checkAccountingYearValidity: () => undefined,
  fixCreditNotes: () => undefined,
  previewInvoiceEmails: () => undefined,
  sendInvoiceEmails: () => undefined,
  currentStateFilter: 'all',
  setCurrentStateFilter: () => undefined,
  downloadInvoices: () => undefined,
  searchValue: undefined,
  setSearchValue: () => undefined,
});

function useLazyEffect(effect: EffectCallback, deps: DependencyList = [], wait = 300) {
  const cleanUp = useRef<void | (() => void)>();
  const effectRef = useRef<EffectCallback>();
  const updatedEffect = useCallback(effect, deps);
  effectRef.current = updatedEffect;
  const lazyEffect = useCallback(
    _.debounce(() => {
      cleanUp.current = effectRef.current?.();
    }, wait),
    []
  );
  useEffect(lazyEffect, deps);
  useEffect(() => {
    return () => {
      if (cleanUp.current instanceof Function) cleanUp.current();
    };
  }, []);
}

export const useClaims = () => {
  const context = useContext(ClaimsContext);
  if (!context) {
    throw new Error('Parent must be wrapped inside ClaimsProvider');
  }
  return context;
};

export const ClaimsProvider: FC = ({ children }) => {
  const { API, defaultErrorHandle } = useApi();
  const { error, success } = useFeedback();
  const [searchParams, setSearchParams] = useSearchParams();

  const [accountingYears, setAccountingYears] = useState<CompleteAccountingYear[]>([]);
  const [currentAccountingYearId, setCurrentAccountingYearId] = useState<string>();
  const [currentAccountingYearReport, setCurrentAccountingYearReport] =
    useState<AccountingYearValidityReport>();

  const currentAccountingYear = accountingYears.find((ay) => ay._id === currentAccountingYearId);

  const [currentAllClaims, setCurrentClaims] = useState<CompleteClaim[]>([]);

  const [claimGroups, setClaimGroups] = useState<ClaimGroup[]>([]);
  const [currentClaimGroupId, setCurrentClaimGroupIdToWrap] = useState<string>(
    searchParams.get('group')
  );
  // console.log(currentClaimGroupId);
  const setCurrentClaimGroupId = useCallback((id: string) => {
    setIsLoading(true);
    setCurrentClaimGroupIdToWrap(id);
  }, []);

  const currentClaimGroup = claimGroups.find((cg) => cg._id === currentClaimGroupId);
  const [currentStateFilter, setCurrentStateFilter] = useState<StateFilterType>(
    (searchParams.get('state') as StateFilterType) || 'all'
  );

  const [searchValue, setSearchValue] = useState<string>('');

  const currentClaims = useMemo(() => {
    return (
      currentStateFilter === 'all'
        ? currentAllClaims
        : currentAllClaims.filter((cc) => cc.state.title === ClaimStateEnum[currentStateFilter])
    ).filter(
      (claim) =>
        claim.payer.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
        (isCompany(claim.payer)
          ? claim.payer.fullName?.toLowerCase().includes(searchValue.toLowerCase())
          : claim.payer.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
            claim.payer.lastName.toLowerCase().includes(searchValue.toLowerCase())) ||
        claim.invoice?._id?.includes(searchValue) ||
        claim.creditNote?._id?.includes(searchValue)
    );
  }, [currentAllClaims, currentStateFilter, searchValue]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    searchParams.set('year', currentAccountingYearId);
    setSearchParams(searchParams);
  }, [currentAccountingYearId, searchParams, setSearchParams]);
  useEffect(() => {
    searchParams.set('group', currentClaimGroupId);
    setSearchParams(searchParams);
    console.log(searchParams);
  }, [currentClaimGroupId, searchParams, setSearchParams]);
  useEffect(() => {
    searchParams.set('state', currentStateFilter);
    setSearchParams(searchParams);
    console.log(searchParams);
  }, [currentStateFilter, searchParams, setSearchParams]);

  const pullClaimGroups = useCallback(() => {
    if (!API || !currentAccountingYear) return;
    // setIsLoading(true);
    API.get(`/claimGroups/ofAccountingYear/${currentAccountingYearId}`)
      .then((res) => {
        console.log('claimgrupen', res.data);
        setClaimGroups(res.data);
      })
      .catch((e: any) => {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
      });
  }, [currentAccountingYearId, API, currentAccountingYear, error]);

  useLazyEffect(pullClaimGroups, [pullClaimGroups]);

  const pullCurrentClaims = useCallback(() => {
    if (!API || !currentAccountingYear) return;
    setIsLoading(true);
    if (currentClaimGroup || currentClaimGroupId === '_default') {
      API.get(`/claims/claimGroup/${currentClaimGroupId}`)
        .then((res) => {
          setCurrentClaims(res.data);
          console.log(res);
          setIsLoading(false);
        })
        .catch((e: any) => {
          setIsLoading(false);
          error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        });
    } else {
      API.get(`/accountingYear/${currentAccountingYearId}/claims`)
        .then((res) => {
          setCurrentClaims(res.data);
          console.log(res);
          setIsLoading(false);
        })
        .catch((e: any) => {
          setIsLoading(false);
          error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        });
    }
  }, [
    API,
    currentAccountingYear,
    currentClaimGroup,
    currentAccountingYearId,
    currentClaimGroupId,
    error,
  ]);

  useLazyEffect(pullCurrentClaims, [pullCurrentClaims], 500);

  useEffect(() => {
    if (!API) return;
    API.get(`/accountingYear`)
      .then((res) => {
        console.log(res.data);
        if (res.data.length === 0) return;
        setAccountingYears(res.data);
        const now = new Date();
        const currentYear = res.data.find(
          (ay: AccountingYear) => new Date(ay.start) < now && new Date(ay.end) > now
        );
        if (currentYear) setCurrentAccountingYearId(currentYear._id);
        else setCurrentAccountingYearId(res.data[0]._id);
      })
      .catch((e: any) => {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
      });
  }, [API, error]);

  const submitNewAccountingYear = useCallback(
    async (accountingYear: AccountingYear): Promise<boolean> => {
      if (!API) return false;
      console.log(accountingYear);
      try {
        const res = await API.post('accountingYear', accountingYear);
        success('Účtovný rok pridaný');
        return true;
      } catch (e: any) {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        return false;
      }
    },
    [API, error, success]
  );

  const pullAccountingYears = useCallback(
    (thenYear: string) => {
      API.get(`/accountingYear`)
        .then((res) => {
          console.log(res.data);
          if (res.data.length === 0) return;
          setAccountingYears(res.data);
          setCurrentAccountingYearId(thenYear);
        })
        .catch((e: any) => {
          error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        });
    },
    [API, error]
  );

  const checkAccountingYearValidity = useCallback(
    async (id: string): Promise<AccountingYearValidityReport> => {
      if (!API) return undefined;
      try {
        const res = await API.get(`accountingYear/${id}/checkValidity`);
        console.log(res);
        setCurrentAccountingYearReport(res.data);
        return res.data;
      } catch (e: any) {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        return undefined;
      }
    },
    [API, error]
  );

  const fixCreditNotes = useCallback(
    async (id: string): Promise<void> => {
      if (!API) return undefined;
      try {
        const res = await API.post(`fixAccountingYear/${id}`);
        console.log(res);
      } catch (e: any) {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
      }
      return undefined;
    },
    [API, error]
  );

  const editAccountingYearCurrent = useCallback(
    async (id: string, lastNumber: number): Promise<boolean> => {
      if (!API) return false;
      try {
        const res = await API.post(`accountingYear/${id}/changeYear`, { lastNumber });
        success('Účtovný rok zmenený');
        pullAccountingYears(id);
        checkAccountingYearValidity(id);
        return true;
      } catch (e: any) {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        return false;
      }
    },
    [API, checkAccountingYearValidity, error, pullAccountingYears, success]
  );

  const deleteClaim = useCallback(
    async (id: string): Promise<boolean> => {
      if (!API) return false;
      try {
        const res = await API.delete(`claims/${id}`);
        success('Pohľadávka zmazaná');
        return true;
      } catch (e: any) {
        error(`${e.message} ${e.response.data.map((d: any) => `\n${d.message}`)}`);
        return false;
      }
    },
    [API, error, success]
  );

  const previewInvoiceEmails = useCallback(
    async (ids: string[]) => {
      if (!API) return undefined;
      console.log(ids);
      try {
        const res = await API.post(`/claims/previewBulkClaimInvoiceEmail`, { ids });
        console.log(res);
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, defaultErrorHandle]
  );

  const sendInvoiceEmails = useCallback(
    async (emails: IEmailPreviewData) => {
      if (!API) return undefined;
      try {
        const res = await API.post(`/claims/sendBulkClaimInvoiceEmail`, emails);
        console.log(res);
        success(`Podarilo sa odoslať ${res.data.sent} z ${res.data.from}`);
        return res.data;
      } catch (e: any) {
        defaultErrorHandle(e);
        return undefined;
      }
    },
    [API, defaultErrorHandle, success]
  );

  const downloadInvoices = useCallback(
    async (
      ids: { id: string; creditNoteDownloadOption: 'include' | 'exclude' | 'only' }[],
      format: string,
      filename: string
    ) => {
      if (!API) return;
      console.log(ids);
      try {
        API.post(`/claims/downloadInvoices`, { ids, format }, { responseType: 'blob' }).then(
          (response) => {
            console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
          }
        );
      } catch (e: any) {
        defaultErrorHandle(e);
      }
    },
    [API, defaultErrorHandle]
  );

  const contextObjects = useMemo(
    () => ({
      currentClaims,
      accountingYears,
      currentAccountingYear,
      setCurrentAccountingYearId,
      claimGroups,
      currentClaimGroup,
      setCurrentClaimGroupId,
      isLoading,
      submitNewAccountingYear,
      pullCurrentClaims,
      editAccountingYearCurrent,
      checkAccountingYearValidity,
      previewInvoiceEmails,
      currentAccountingYearReport,
      sendInvoiceEmails,
      currentStateFilter,
      setCurrentStateFilter,
      downloadInvoices,
      searchValue,
      setSearchValue,
      fixCreditNotes,
      currentClaimGroupId,
    }),
    [
      currentClaims,
      accountingYears,
      currentAccountingYear,
      claimGroups,
      currentClaimGroup,
      isLoading,
      submitNewAccountingYear,
      pullCurrentClaims,
      editAccountingYearCurrent,
      checkAccountingYearValidity,
      previewInvoiceEmails,
      currentAccountingYearReport,
      sendInvoiceEmails,
      currentStateFilter,
      downloadInvoices,
      fixCreditNotes,
      searchValue,
      currentClaimGroupId,
    ]
  );

  return <ClaimsContext.Provider value={contextObjects}>{children}</ClaimsContext.Provider>;
};
