import { FC, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Box, Chip, IconButton, MenuItem, Select, Stack, Tooltip, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { CancelPresentation, Delete, FileDownloadOff } from '@mui/icons-material';
import { ClaimProvider, useClaim } from '../../ctx/Claim.Context';
import { fDateTime } from '../../utils/formatTime';
import { ClaimGroup, CompleteClaim, useClaims } from '../../ctx/Claims.Context';
import { fEur } from '../../utils/formatNumber';
import { applyDiscount } from '../../utils/claimsCalculations';
import ClaimStateChip from './ClaimStateChip';
import ClaimStateChanger from './ClaimStateChanger';
import { isCompany } from '../../ctx/contacts/Contact.Context';
import MyModal from '../MyModal';
import Button from '../../theme/overrides/Button';
import AcceptRejectButtonPair from '../AcceptRejectButtonPair';

interface IClaimColumns {
  reloadData?: () => void;
}

const claimColumns = ({ reloadData }: IClaimColumns): GridColDef[] => [
  {
    field: 'issued',
    headerName: 'Vystavená',
    flex: 1,
    renderCell: (params) => <Box>{fDateTime(params.row.issued)}</Box>,
  },
  {
    field: 'claimGroups',
    headerName: 'Skupina',
    flex: 1,
    renderCell: ({ row }) => (
      <ClaimProvider id={row._id} initialData={row} dataProvider={false}>
        <div className="flex flex-row p-2">
          <ClaimGroupSelector />
        </div>
      </ClaimProvider>
    ),
  },
  {
    field: 'invoice',
    headerName: 'Č. faktúry',
    flex: 1,
    renderCell: ({ row }) => <ClaimRedirecter claimData={row} />,
  },

  {
    field: 'payer',
    headerName: 'Platiteľ',
    flex: 1,
    // eslint-disable-next-line react/jsx-no-useless-fragment
    renderCell: ({ row }) => {
      if (isCompany(row.payer)) return row.payer.companyName;
      return `${row.payer.firstName} ${row.payer.lastName}`;
    },
  },
  {
    field: 'total',
    headerName: 'Suma',
    flex: 1,
    renderCell: ({ row }) => <Box>{fEur(applyDiscount(row.rawTotal, row.discount))}</Box>,
  },

  {
    field: 'state',
    headerName: 'Stav',
    flex: 1,
    renderCell: ({ row }) => <ClaimStateChip {...row.state} />,
  },
  {
    field: 'emails',
    headerName: 'Emailov',
    flex: 0.5,
    renderCell: ({ row }) => row.emails?.length || 0,
  },
  {
    field: 'actions',
    headerName: '',
    width: 250,
    renderCell: ({ row }) => (
      <ClaimProvider id={row._id} initialData={row} dataProvider={false}>
        <Stack direction="row">
          <ClaimStateChanger
            afterChange={reloadData}
            button={
              <IconButton>
                <ChangeCircleIcon />
              </IconButton>
            }
          />
          <ClaimDownloader />
          {row.creditNote ? <CreditNoteDownloader /> : <CreditNoteCreator afterDone={reloadData} />}
          <ClaimDeleter afterDone={reloadData} />
        </Stack>
      </ClaimProvider>
    ),
  },
];

const ClaimDownloader: FC = () => {
  const { downloadInvoice } = useClaim();
  return (
    <Tooltip title="Stiahnuť faktúru">
      <IconButton onClick={downloadInvoice}>
        <FileDownloadIcon />
      </IconButton>
    </Tooltip>
  );
};

interface IClaimActionComponent {
  afterDone?: () => void;
}

const CreditNoteCreator: FC<IClaimActionComponent> = ({ afterDone }) => {
  const { createCreditNote } = useClaim();
  return (
    <Tooltip title="Vytvoriť dobropis">
      <IconButton
        onClick={async () => {
          await createCreditNote();
          if (afterDone) afterDone();
        }}
      >
        <CancelPresentation />
      </IconButton>
    </Tooltip>
  );
};

const CreditNoteDownloader: FC = () => {
  const { downloadCreditNote } = useClaim();
  return (
    <Tooltip title="Stiahnuť dobropis">
      <IconButton onClick={downloadCreditNote}>
        <FileDownloadOff />
      </IconButton>
    </Tooltip>
  );
};

const ClaimGroupSelector: FC<IClaimActionComponent> = ({ afterDone }) => {
  const { claimGroups } = useClaims();
  const { claimData, changeGroup } = useClaim();
  const myGroup = claimData.claimGroups?.[0] ?? '_default';
  console.log(claimData.claimGroups);
  console.log(myGroup);
  // const [claimGroupValue, setClaimGroupValue] = useState<string>(claimData.claimGroups?.[0]);
  const handleSelectGroup = (id: string) => {
    changeGroup(id);
    // setSelectedClaimGroup(claimGroups.find((cg) => cg._id === id));
  };
  // const [selectedGroup, setSelectedClaimGroup] = useState<ClaimGroup>(
  //   claimGroups.find((cg) => cg._id === myGroup)
  // );

  return (
    <Select value={myGroup} onChange={(e) => handleSelectGroup(e.target.value)} size="small">
      {/* default */}
      <MenuItem value="_default">
        <em>Žiadna</em>
      </MenuItem>
      {claimGroups.map((cg) => (
        <MenuItem key={`cg-select-${cg._id}`} value={cg._id}>
          {cg.title}
        </MenuItem>
      ))}
    </Select>
  );
};

const ClaimDeleter: FC<IClaimActionComponent> = ({ afterDone }) => {
  const { deleteClaim, claimData } = useClaim();
  // const { claimGroups } = useClaims();
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  return (
    <>
      <MyModal isOpened={modalOpened} handleClose={() => setModalOpened(false)}>
        <Stack alignItems="center" gap={3} direction="column">
          Vymazať pohľadávku pre {claimData.payer.email}, číslo faktúry {claimData.invoice._id}
          {claimData.creditNote
            ? `, číslo dobropisu ${claimData.creditNote._id}`
            : ', bez dobropisu'}
          ?
          <Stack direction="row" gap={2}>
            <AcceptRejectButtonPair
              onConfirm={async () => {
                await deleteClaim();
                if (afterDone) afterDone();
              }}
              onReject={() => setModalOpened(false)}
            />
          </Stack>
        </Stack>
      </MyModal>
      <Tooltip title="Zmazať pohľadávku">
        <IconButton onClick={() => setModalOpened(true)}>
          <Delete />
        </IconButton>
      </Tooltip>
    </>
  );
};

interface IClaimRedirecter {
  claimData: CompleteClaim;
}
const ClaimRedirecter: FC<IClaimRedirecter> = ({ claimData }) => {
  const navigate = useNavigate();
  return (
    <Tooltip title="Otvoriť">
      <Link to={`/claims/${claimData._id}`}>
        <Typography sx={{ fontFamily: 'monospace' }}>{claimData.invoice._id}</Typography>
      </Link>
    </Tooltip>
  );
};

interface IClaimsTable {
  claims: Array<CompleteClaim>;
  reloadData?: () => void;
}

export const ClaimsTable: FC<IClaimsTable> = ({ claims, reloadData }) => {
  return (
    <DataGrid
      autoHeight
      columns={claimColumns({ reloadData })}
      rows={claims.map((c) => ({ ...c, id: c._id }))}
    />
  );
};
